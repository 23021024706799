<template>
  <div>
    <div class="alert alert-success" role="alert" v-if="success">
      {{ alertMessage }}
    </div>
    <div class="alert alert-danger" role="alert" v-if="errorCustom">
      {{ alertMessage }}
    </div>
    <div class="alert alert-warning" role="alert" v-if="loading">
      {{ alertMessage }}
    </div>
    <h2>971 507896516</h2>

    <form class="login" @submit.prevent="getLoginType">
      <h2>{{ locale.loginByPhone }}</h2>
      <label for="phoneNumber">
        <div class="dropdown show">
          <a
              class="btn dropdown-toggle"
              href="#"
              role="button"
              id="dropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
          >
            {{ countryLogin }}
            <img
                :src="
                require(`../assets/flags/${selectedCountryInitials.toLowerCase()}.png`)
              "
                alt=""
                style="width: 35px; margin-bottom: 2.5px"
            />
          </a>

          <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <a
                class="dropdown-item d-flex justify-content-between"
                style="border-bottom: 1px solid lightgrey"
                v-for="(country, index) in countries"
                :key="index"
                @click="selectCountry(country.name, country.icon, country.api)"
            >
              {{ countryCode.countriesCode[country.name] }}

              <img
                  :src="
                  require(`../assets/flags/${country.name.toLowerCase()}.png`)
                "
                  alt="icon"
                  style="width: 30%"
              />
            </a>
          </div>
        </div>
      </label>

      <input
          type="number"
          id="phoneNumber"
          v-model="phoneNumberLogin"
          placeholder="12 345 678"
      />
      <br/>
      <button id="sign-in-button">{{ locale.login }}</button>
    </form>
    <div id="recaptcha-container" style="width: 500px"></div>

    <!-- <form class="codeLogin" @submit.prevent="confirmPhoneCode">
      <h2>Enter the code</h2>
      <input
        type="number"
        v-model="phoneNumberLoginCode"
        placeholder="6 digits code"
      />
      <br />
      <button>Confirm</button>
    </form> -->

    <b-modal
        ref="providerLogin"
        centered
        :title="modalHeaderOptions.title"
        hide-footer
    >
      <p class="my-4">{{ modalMesage }}</p>
      <form ref="form" @submit.stop.prevent="">
        <b-form-group
            label="Code"
            id="phoneVerificationByLocalProvider"
            type="number"
            invalid-feedback="Code is required"
        >
          <b-form-input
              id="phoneVerificationByLocalProvider"
              v-model="providerLoginCode"
              required
          ></b-form-input>
          <div class="row">
            <hr/>
            <button @click="modalFooterOptions.btnOnClick">
              {{ locale.confirm }}
            </button>
          </div>
        </b-form-group>
      </form>
    </b-modal>
    <loading :loading="loading"/>
  </div>
</template>

<script>
import countriesCode from "../scripts/countries";
import axios from "axios";
import {
  API_ENDPOINTS,
  TARGET_NEW_USER,
  TARGET_VERIFICATION,
} from "../scripts/request";
import firebase from "firebase";
import "bootstrap";
import {LocaleArabic, LocaleEnglish} from "../scripts/Locale";

export default {
  name: "Login",
  props: {
    msg: String,
  },
  data() {
    return {
      countryCode: countriesCode,
      selectedCountryCode: "",
      locale: null,
      countries: [], // loops through this to populate country dropdown
      selectedCountryLogo: "", // its the logo url for the selected country
      selectedCountryInitials: "lb", //for ex LB it's the selected country initials in the dropdown
      alertMessage: "", // for alerts on top of the page
      success: false, //for alerts on top of the page
      errorCustom: false, // for alerts on top of the page
      loading: false, // for alerts on top of the page
      countryLogin: "",
      phoneNumberLogin: null, //phone number
      phoneNumberLoginCode: "", //for verification code
      modalHeaderOptions: {}, // used for the modal that shows after login
      modalFooterOptions: {
        btnOnClick: () => {
          console.log("hello");
        },
      }, // used for the modal that shows after login
      modalMesage: {}, //used to set modal body message
      providerLoginCode: "", //used to take the user inputed code from the modal and sent to the loginByProvider
    };
  },
  beforeMount() {
    this.setLanguage();
    this.getCountries();
  },
  created() {
    this.setLanguage();
  },
  methods: {
    setLanguage() {
      this.loading = true;
      let localLang = navigator.language;
      let lang;
      if (localLang.includes("-")) {
        lang = localLang.split("-")[0];
      } else {
        lang = localLang;
      }
      console.log("language is: ", lang);
      this.$session.start();

      if (lang === "ar") {
        this.$session.set("lang", "ar");
        this.$session.set("direction", "rtl");
        this.locale = LocaleArabic;
      } else {
        this.$session.set("lang", "en");
        this.$session.set("direction", "ltr");
        this.locale = LocaleEnglish;
      }
      this.loading = false;
    },
    checkApiResponse(result) {
      if (result.headers.sessioncounter) {
        this.$session.set("sessionCounter", result.headers.sessioncounter);
      }
      if (!result.data.status) {
        // error
        this.$message.error(result.data.dialog.message);
        console.error(`error in (${result.config.url})`, result);
        if (result.data.code === "auth.session_expired") {
          // log user out
          this.logout();
        }
      } else {
        return true;
      }
      return false;
    },
    getAxios() {
      return axios.create({
        headers: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Origin": "*",
          language: this.$session.get("lang") || "en",
          itelVersion: "1000",
          uid: "uidtest1.3",
          brand: "Web",
          model: "Web",
          osVersion: "unix",
          operatingSystem: "4",
          sessionId: `${this.$session.get("sessionId")}`,
          token: `${this.$session.get("token")}`,
          deviceId: `${this.$session.get("deviceid") || ""}`,
          sessionCounter: `${this.$session.get("sessionCounter") || 0}`,
          counter: `${this.$session.get("counter")}`,
        },
      });
    },
    selectCountry(countryName, countryLogo, baseURI) {
      // add baseURI to session -TODO
      this.selectedCountryLogo = countryLogo;
      this.selectedCountryInitials = countryName;
      this.countryLogin = countriesCode.countriesCode[countryName];
      this.$session.set("baseURI", baseURI);
    },
    getLoginType() {
      // checks if server needs this phone num to be verified using firebase or localprovider

      // send info to api

      if (!this.$session.get("baseURI")) {
        this.$message.error("Please choose a country");
        return;
      }
      if (!this.phoneNumberLogin) {
        this.$message.error("Please input your phone number");
        return;
      }
      this.getAxios()
          .post(`${this.$session.get("baseURI")}auth/register/phone`, {
            code: this.countryLogin,
            msisdn: this.phoneNumberLogin,
            country: this.selectedCountryInitials,
          })
          .then((result) => {
            console.log(result);
            if (this.checkApiRes(
                result,
                {
                  code: this.countryLogin,
                  msisdn: this.phoneNumberLogin,
                  country: this.selectedCountryInitials,
                },
                "auth/register/phone",
                "post")) {
              // ok
              // if api returns 1 do it from local provider
              if (result.data.data.msgType == "1") {
                // set modal header and footer options
                this.modalHeaderOptions = {title: result.data.dialog.title};
                this.modalFooterOptions = {
                  btnOnClick: () => {
                    this.loginByProvider(
                        result.data.data.msisdn,
                        result.data.data.registrationId,
                        this.providerLoginCode
                    );
                  },
                };
                this.modalMesage = result.data.dialog.message;
                this.openProviderLoginModal();
              } else if (result.data.data.msgType == "2") {
                // if api returns 2 do it from firebase
              }
            }
          });
    },
    loginByProvider(msisdn, registrationId, code) {
      console.log(msisdn, registrationId, code);
      if ((!msisdn, !registrationId, !code)) {
        console.log(
            "one of these msisdn, registrationId, code is missing: " + msisdn,
            registrationId,
            code
        );
        return;
      }
      this.getAxios()
          .post(`${this.$session.get("baseURI")}auth/verify/phone`, {
            //body
            msisdn: msisdn,
            registrationId: registrationId,
            code: code,
          })
          .then((result) => {
            if (this.checkApiRes(result, {
              //body
              msisdn: msisdn,
              registrationId: registrationId,
              code: code,
            }, "auth/verify/phone", "post")) {
              // ok
              if (result.data.status) {
                console.log("DATAAAAA", result);
                if (result.data.data.newUser === true) {
                  console.log("new user");
                  this.$session.set("msisdn", result.data.data.msisdn);
                  this.$session.set("sessionId", result.data.data.sessionId);
                  this.$session.set("token", result.data.data.token);
                  this.$session.set("counter", result.data.data.counter);
                  this.$session.set("userId", result.data.data.userId);
                  // get account type new user
                  this.getAccountType(TARGET_NEW_USER);
                } else {
                  this.$session.set("msisdn", result.data.data.msisdn);
                  this.$session.set("sessionId", result.data.data.sessionId);
                  this.$session.set("token", result.data.data.token);
                  this.$session.set("counter", result.data.data.counter);
                  this.$session.set("userId", result.data.data.userId);
                  // get account type verification
                  this.getAccountType(TARGET_VERIFICATION);
                }
                // sessionId, sessionCounter and token to be saved and added to header
              } else {
                this.$message.error(result.data.dialog.message);
              }
            }
          });
    },
    getAccountType(targetUser) {
      console.log(targetUser);
      console.log(this.$session.get("token"), this.$session.get("sessionId"));
      this.getAxios()
          .post(`${this.$session.get("baseURI")}auth/accounttypes`, {
            target: targetUser,
          })
          .then((result) => {
            console.log(result);
            if (this.checkApiRes(result, {target: targetUser}, "auth/accounttypes", "post")) {
              // ok
              console.log("from getAccountType", result);
              if (result.data.status) {
                this.$session.set("loggedInbyPhoneProvider", true);
                this.$session.set("profile_data", result);
                if (targetUser == TARGET_NEW_USER) {
                  // this.$router.replace("/Profile").catch(() => {});
                  this.$router.push({
                    name: "Profile",
                    params: {target: TARGET_NEW_USER},
                  });
                } else if (targetUser == TARGET_VERIFICATION) {
                  this.$router.replace("/").catch(() => {
                  });
                }
              } else {
                console.log(result);
                console.error(result.data.dialog);
              }
            }
          });
    },
    loginByFirebase(msisdn, registrationId, idToken) {
      console.log(msisdn, registrationId, idToken);
      if ((!msisdn, !registrationId, !idToken)) {
        console.log(
            "one of these msisdn, registrationId, idToken is missing: " + msisdn,
            registrationId,
            idToken
        );
        return;
      }
      this.getAxios()
          .post(`${this.$session.get("baseURI")}auth/verify/firebase`, {
            //body
            msisdn: msisdn,
            registrationId: registrationId,
            idToken: idToken,
          })
          .then((result) => {
            console.log(result);
            if (this.checkApiRes(result, {
              //body
              msisdn: msisdn,
              registrationId: registrationId,
              idToken: idToken,
            }, "auth/verify/firebase", "post")) {
              // ok
              if (result.headers.sessioncounter) {
                this.$session.set(
                    "sessionCounter",
                    result.headers.sessioncounter
                );
              }
              console.log(result);
            }
          });
    },
    getCountries() {
      this.setLanguage();
      this.getAxios()
          .get(API_ENDPOINTS.getCountries)
          .then((result) => {
            console.log(result);
            if (this.checkApiRes(result, null, "", "get")) { // here we sent ApiBody null because there wont be a purchase confirm on that api
              // ok
              this.$session.set("deviceid", result.headers.deviceid);
              let servers = result.data.data.servers;
              servers.forEach((server) => {
                for (let i = 0; i < server.countries.length; i++) {
                  if (result.data.data.defaultCountry == server.countries[i]) {
                    this.selectCountry(
                        server.countries[i],
                        server.icon,
                        server.api
                    );
                  }
                  this.countries.push({
                    id: server.id,
                    api: server.api,
                    icon: server.icon,
                    name: server.countries[i],
                  });
                }
              });
            }
          });
    },
    showAlert(alertMessage, whichAlert) {
      this.alertMessage = alertMessage;
      switch (whichAlert) {
        case "loading":
          this.loading = true;
          this.errorCustom = false;
          this.success = false;
          break;
        case "error":
          this.loading = false;
          this.errorCustom = true;
          this.success = false;
          break;
        case "success":
          this.loading = false;
          this.errorCustom = false;
          this.success = true;
          break;
        default:
          break;
      }
    },
    loginWhishFirebase() {
      firebase.auth().useDeviceLanguage();
      if (this.countryLogin && this.phoneNumberLogin)
        if (this.phoneNumberLogin) {
          this.loading = true;
          const phoneNumber = this.countryLogin + this.phoneNumberLogin;
          const appVerifier = window.recaptchaVerifier;
          console.log(phoneNumber, appVerifier);
          firebase
              .auth()
              .signInWithPhoneNumber(phoneNumber, appVerifier)
              .then((confirmationResult) => {
                this.showAlert(
                    "SMS sent, check your phone for a verification code.",
                    "loading"
                );
                // SMS sent. Prompt user to type the code from the message, then sign the
                // user in with confirmationResult.confirm(code).
                window.confirmationResult = confirmationResult;
                // ...
              })
              .catch((error) => {
                console.log(error);
                this.showAlert("SMS not sent, Error, please try again.", "error");
                // Error; SMS not sent
                // ...
              });
        }
    },
    confirmPhoneCode() {
      const code = this.phoneNumberLoginCode;
      window.confirmationResult
          .confirm(code)
          .then((result) => {
            // User signed in successfully.
            console.log("ZABATTTT", result.user);
            this.showAlert(
                "Successfully signed in, please wait for the page to redirect.",
                "success"
            );
            // ...
          })
          .catch((error) => {
            console.log(error);
            this.showAlert(
                "Couldn't sign you in. Wrong verification code.",
                "error"
            );
            // User couldn't sign in (bad verification code?)
            // ...
          });
    },
    openProviderLoginModal() {
      this.$refs["providerLogin"].show();
    },
    closeProviderLoginModal() {
      this.$refs["providerLogin"].hide();
    },
  },
  mounted() {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            console.log(response);
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            // ...
            this.loginWhish();
          },
          "expired-callback": () => {
            console.log("expired");
            // Response expired. Ask user to solve reCAPTCHA again.
            // ...
          },
        }
    );
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
select {
  padding: 5px;
  width: fit-content;
  margin: 5px;
}

input {
  padding: 5px;
  width: fit-content;
  margin: 5px;
}

#dropdownMenuLink {
  color: black;

  a.dropdown-item {
    color: black;
  }
}

button {
  padding: 10px;
  border-radius: 45px;
  border: none;
  background-color: red;
  color: white;
  margin: 5px;
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}
</style>
